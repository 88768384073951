import dayjs, { type Dayjs } from "dayjs";
import { filter } from "lodash";
import type { Event, EventOccurrence } from "~~/models/event";

const parseDates = (dates: (string | undefined)[]): Dayjs[] => {
  return filter(dates).map((date) => dayjs.unix(Number(date!)));
};

export const getEventStartTime = (
  event: Event,
  occurrence?: EventOccurrence
) => {
  if (occurrence) {
    return occurrence.starts_at
      ? dayjs.unix(Number(occurrence.starts_at))
      : undefined;
  }
  const occurrences = event.occurrences || [];
  const startDates = occurrences.map((occur) => occur.starts_at!);
  return earliestDate(parseDates(startDates));
};

export const getEventEndTime = (event: Event, occurrence?: EventOccurrence) => {
  if (occurrence) {
    return occurrence.ends_at
      ? dayjs.unix(Number(occurrence.ends_at))
      : undefined;
  }
  const occurrences = event.occurrences || [];
  const endDates = occurrences.map((occur) => occur.ends_at!);
  return latestDate(parseDates(endDates));
};

export const getEventDefaults = (): Event => {
  const defu: any = {
    gallery: [],
    tags: [],
    tickets: [],
    occurrences: [],
  };
  return defu;
};
